footer.main-footer {
  @apply bg-zz-blue-dark text-zz-blue;

  .inner {
    padding: 35px 0 10px 0;

    .logo {
      max-width: 350px;

      margin-bottom: 35px;
    }

    .footer-content {
      @apply grid md:grid-cols-2 gap-8;

      .left {
        a {
          @apply text-zz-orange;
          &:hover {
            @apply text-zz-orange;
            text-decoration: underline !important;
          }
        }
      }

      .right {
        p {
          margin-bottom: 15px;
        }

        .icons {
          a {
            margin-right: 10px;
            font-size: 25px;
            line-height: 1;
            transition: all .2s;

            &:hover {
              @apply text-zz-orange;
            }
          }
        }
      }
    }

    .bottom-content {
      padding-top: 35px;
      @apply flex-col lg:flex-row;
      display: flex;
      justify-content: space-between;

      .right {
        @apply mt-8 lg:mt-0;

        display: flex;
        align-items: flex-end;

        a {
          &:hover {
            opacity: .8;
          }
        }

        img {
          margin-top: -8px;
          display: inline;
          height: 18px;
        }
      }
    }
  }
}