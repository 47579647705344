.front-page, .default-page, .landingpage-content {
  .top-content {
    @apply bg-zz-blue text-zz-blue-dark;

    .content {
      @apply flex-col lg:flex-row;

      display: flex;
      justify-content: space-between;

      padding-top: 65px;

      padding-bottom: 45px;

      .text-content {
        max-width: 500px;
      }

      .buttons {
        @apply pt-8 flex flex-col;
        align-items: flex-end;

        a {
          width: -webkit-fill-available;
        }
      }
    }
  }

  .thumbnail {
    img {
      width: 100%;
      height: 100%;
      max-height: 35vw;
      object-fit: cover;
    }
  }

  .main-content {
    @apply text-zz-blue-dark;
  }
}