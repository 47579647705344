.main-header {
  @apply bg-zz-blue sticky top-0 flex flex-col;
  height: fit-content;
  transition: .2s all;
  z-index: 999;

  &:has(.phone-menu.is-active) {
    @media (max-width: 991px) {
      @apply h-screen;
    }
  }

  .content {
    @apply py-8 px-0 flex justify-between;
    transition: all .2s;

    .left {
      @apply flex items-center;
      max-width: 60vw;
      width: fit-content;

      img {
        min-height: 30px;
        max-width: 350px;
        width: 100%;
      }
    }

    .right {
      @apply flex items-center;

      .menu {
        @apply hidden lg:flex m-0 p-0;

        li {
          margin-left: 30px;

          a {
            font-weight: bold;
            @apply text-zz-blue-dark
          }
        }
      }

      .phone-menu-trigger {
        @apply lg:hidden;

        i {
          font-style: normal;

          &:before {
            @apply text-zz-blue-dark;
            font-size: 3rem;
            font-family: 'Font Awesome 5 Pro';
          }
        }
      }
    }
  }

  &[data-has-scrolled="true"] {
    .content {
      padding: 15px 0;
    }

    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  .phone-menu {
    @apply hidden my-24;

    &-inner {
      .menu {
        @apply flex flex-col gap-6;

        .menu-item {
          a {
            @apply text-zz-blue-dark uppercase;
            font-size: 30px;
          }
        }
      }
    }

    &.is-active {
      @apply flex lg:hidden;
    }
  }
}

html:has(.phone-menu.is-active) {
  overflow: hidden;
}