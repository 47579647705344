.faq-container {
  .faq-items {
    max-width: 750px;

    .faq-item {
      .faq-item-header {
        @apply bg-zz-blue-dark text-white;

        padding: 1.5rem 2rem;
        cursor:pointer;

        border-bottom: 1px solid rgba(0,0,0,.125);

        display: flex;
        justify-content: space-between;
        align-items: center;

        .faq-item-header-title {
          font-size: 18px;
          line-height: 1.2;
        }
      }

      .faq-item-content {
        display: none;
        @apply text-zz-blue-dark;

        flex: 1 1 auto;
        padding: 1.25rem;
        border: 1px solid rgba(0,0,0,.125);
        background-color: #fff;
        background-clip: border-box;
      }

      .show-on-open {
        display: none;
      }

      &.open {
        .faq-item-content {
          display: block;
        }

        .show-on-close {
            display: none;
        }

        .show-on-open {
            display: block;
        }
      }
    }
  }
}