.klanten {
  @apply text-zz-blue-dark;

  li {
    .inner {
      @apply flex justify-center items-center h-full;

      img.thumbnail {
        margin: auto;
        max-width: 150px;
      }
    }
  }
}