$product-border-radius: 40px;

.producten {
  @apply text-zz-blue-dark;

  .producten-holder {
    @apply grid md:grid-cols-2 gap-8;

    .single-product {
      .content {
        display: flex;
        flex-direction: column;

        height: 100%;
        border-radius: $product-border-radius;

        .image {
          img {
            width: 100%;
            border-radius: $product-border-radius $product-border-radius 0 0;
            height: 350px;
            object-fit: cover;
          }
        }

        .text-content {
          padding: 35px 25px;
          border-radius: 0 0 $product-border-radius $product-border-radius;

          flex: 1;
          display: flex;
          flex-direction: column;

          .button-container {
            margin-top: 30px;

            flex: 1;
            display: flex;
            align-items: flex-end;

            .btn {
              width: 100%;
              text-align: center;
            }
          }

          @apply bg-zz-blue text-zz-blue-dark;
        }
      }

      &[data-even="true"] {
        .content {
          .text-content {
            @apply bg-zz-green text-zz-green-dark;
          }
        }
      }
    }
  }
}