.form-styling {
  max-width: 1000px;

  input {
    @apply border-zz-green-dark text-zz-green-dark rounded-full;
    background-color: transparent;
    border: 2px solid;
    padding: 15px !important;

    font-size: 18px;
    line-height: 1.2;

    outline: none !important;

    &::placeholder {
      @apply text-zz-green-dark;
    }
  }

  .gfield_checkbox {
    .gchoice {
      display: flex;
      align-items: center;
    }

    input {
      margin-top: 0;
      margin-right: 10px;
      height: 35px;
      width: 35px;
      position: relative;
      visibility: hidden;

      &:after {
        margin: 0;
        @apply border-zz-green-dark;
        border: 2px solid;

        border-radius: 5px;

        visibility: visible;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        transition: all .2s;

        @apply text-zz-green-dark;

        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "";
      }

      &:checked {
        &:after {
          content: "\f00c";
        }
      }
    }
  }

  input[type="submit"] {
    border: none;
    transition: all .2s;
    @apply bg-zz-orange text-zz-green-dark;

    &:hover {
      @apply bg-zz-green-dark text-zz-orange;
    }
  }
}