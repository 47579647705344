.waarom_smaakonderzoek {
  @apply text-zz-green-dark;

  .waarom-items {
    @apply gap-8;

    .waarom-item {
      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
      }

      h4, h5, h6 {
        font-size: 16px;
      }

      .inner {
        @apply bg-zz-green rounded-3xl;
        padding: 35px;
      }
    }
  }
}