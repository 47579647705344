.smaakexpiditie {
  @apply bg-zz-blue text-zz-blue-dark relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
  }

  .container {
    position: relative;
    z-index: 1;
    padding-top: 80px;

    .smaakexpiditie_image {
      margin-top: 15px;

      img {
        width: 100%;
        max-height: 500px;
        object-fit:cover;
      }
    }
  }
}