@import 'variables';

@import 'components';

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #fff;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > main {
    flex: 1;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-weight: bold;

  font-size: clamp(22px, 47px, 8vw);

  line-height: 1.2;
}

h2,h3,h4,h5,h6 {
  font-size: clamp(20px, 36px, 7vw);
  margin-bottom: 35px;
}

h3,h4,h5,h6 {
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 1.2;
}

.top-spacing {
  margin-top: 80px;
}

.the_content {
  max-width: 1000px;

  p {
    margin-bottom: 25px;
  }

  ul {
    li {
      display: flex;
      margin-bottom: 10px;

      &:before {
        margin-right: 5px;
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "\f00c";
      }
    }
  }
}

a {
  text-decoration: none !important;
}
