/**
  Import tailwind base styling
 */
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

// core slider styling
@import "base/splide-core.min.css";

// core bootstrap styling
@import "base/bootstrap.min.css";

// Import theme styling
@import '../../../scss/style';