.template-consumenten {
  .text--blue {
    color: #002878;
  }

  .custom_thumbnail {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
}