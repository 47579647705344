$spacing: 4vw;

.spacing {
  padding-top: $spacing;
  padding-bottom: $spacing;

  &-top {
    padding-top: $spacing;
  }

  &-bottom {
    padding-bottom: $spacing;
  }
}