.btn {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;

  transition: all .2s;

  padding: 15px 25px;

  &-primary {
    @apply bg-zz-orange text-zz-blue-dark rounded-full;

    &:hover {
      @apply bg-zz-blue-dark text-zz-orange;
    }
  }

  &-secondary {
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    border: {
      width: 2px;
      style: solid;
    }

    @apply border-zz-orange;

    --tw-text-opacity: 1;
    color: rgba(0, 40, 120, var(--tw-text-opacity));
    min-width: 313.58px;
    text-align: center;
    margin-top: 30px;

    &:hover {
      background-color: rgba(253, 219, 0, var(--tw-bg-opacity));
    }
  }
}